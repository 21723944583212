import { NgModule, ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';


// ReactiveForms
import { ReactiveFormsModule } from '@angular/forms';

// NgCharts
import { NgChartsModule } from 'ng2-charts';

import { getAnalytics } from "firebase/analytics";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule, 
        IonicModule.forRoot({ mode: 'ios' }), 
        ReactiveFormsModule, 
        AppRoutingModule, 
        NgChartsModule,
        
    ],
    providers: [{ 
        provide: RouteReuseStrategy, 
        useClass: IonicRouteStrategy,
        
     }],
    bootstrap: [AppComponent]
})
export class AppModule { }
